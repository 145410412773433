<template>
  <CentralizeLayout>
    <div class="bg-1"></div>
    <div class="bg-2"></div>
    <b-col lg="4">
      <div class="text-center mb-4">
        <logo name="logo" />
      </div>
      <b-alert
        class="mt-3"
        :show="hasAuthenticationStatus || $v.form.email.$invalid"
        :variant="'danger'"
        >{{ $t(`error.${errorCode}`) }}</b-alert
      >
      <b-card class="p-2 auth-card">
        <h5 class="mt-3">{{ $t("activateAccount") }}</h5>
        <b-form class="mt-3" novalidate @submit.prevent="onSubmit">
          <b-form-group :label="$t('email')">
            <b-form-input
              v-model.trim.lazy="$v.form.email.$model"
              type="email"
              required
              :state="validateState('email')"
              :disabled="isLoading"
              @blur="$v.form.email.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("emailErrMsg")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <div class="d-flex justify-content-between align-item-center">
              <label for="password">{{ $t("confirmationCode") }}</label>
              <a
                v-if="!timerEnabled"
                class="text-primary cursor-pointer"
                @click.prevent="resendCode()"
                >Resend</a
              >
              <p class="text-description pr-2" v-if="timerEnabled">
                {{ timerCount }}
              </p>
            </div>
            <b-form-input
              v-model.trim.lazy="form.code"
              type="password"
              required
              maxlength="6"
              :state="validateState('code')"
              :disabled="isLoading"
              @blur="$v.form.code.$touch()"
            />
          </b-form-group>
          <b-form-invalid-feedback>{{
            $t("codeErrMsg2")
          }}</b-form-invalid-feedback>
          <b-button
            class="mt-4"
            block
            :disabled="isLoading"
            type="submit"
            variant="primary"
          >
            <b-icon-arrow-clockwise v-show="isLoading" animation="spin" />
            {{ $t("startSurvein") }}
          </b-button>
        </b-form>
        <div class="mt-2 d-flex justify-content-center">
          <b-button
            variant="link"
            :to="{ name: 'Login', query: { lang: this.$route.query.lang } }"
          >
            {{ $t("returnLogin") }}
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col lg="4" class="text-footer">
      <div>
        <span class="mr-2">{{ $t("contact") }}</span>
        <span class="mr-2">{{ $t("legal") }}</span>
        <span>{{ $t("privacy") }}</span>
      </div>

      <div class="ml-auto">
        <b-dropdown class="language-dropdown" dropup no-caret right>
          <template #button-content>
            <div class="d-flex flex-row align-items-center">
              <icon class="mr-2" name="language" />
              <span v-if="$root.$i18n.locale === 'en'" style="font-size: 12px;"
                >English</span
              >
              <span v-if="$root.$i18n.locale === 'id'" style="font-size: 12px;"
                >Indonesia</span
              >
              <b-icon
                class="caret ml-1"
                icon="caret-up-fill"
                font-scale="0.85"
              />
            </div>
          </template>
          <b-dropdown-item @click="setLanguageEn">English</b-dropdown-item>
          <b-dropdown-item @click="setLanguageId"
            >Bahasa Indonesia</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </b-col>
  </CentralizeLayout>
</template>

<i18n>
{
  "en": {
    "activateAccount": "Activate account",
    "email": "Email",
    "emailErrMsg": "Enter your email address.",
    "startSurvein": "Start using Survein",
    "codeErrMsg2": "6 digits code is required.",
    "error": {
      "ExpiredCodeException": "Invalid code provided, please request a code again.",
      "unspecific": "Email or Confirmation code invalid."
    },
    "confirmationCode": "Confirmation code",
    "returnLogin": "Return to log in",
    "confirmationResend": "Confirmation code has been sent to",
    "contact": "Contact",
    "legal": "Legal",
    "privacy": "Privacy & terms",
    "languageSaved": "Language is saved"
  },
  "id": {
    "activateAccount": "Aktifkan akun",
    "email": "Email",
    "emailErrMsg": "Masukkan alamat email anda.",
    "startSurvein": "Mulai menggunakan Survein",
    "codeErrMsg2": "6 angka kode diperlukan.",
    "error": {
      "ExpiredCodeException": "Kode yang diberikan tidak valid, harap minta kode lagi.",
      "unspecific": "Email atau kode konfirmasi tidak valid."
    },
    "confirmationCode": "Kode konfirmasi",
    "returnLogin": "Kembali untuk masuk",
    "confirmationResend": "Kode konfirmasi telah di kirim ke",
    "contact": "Kontak",
    "legal": "Hukum",
    "privacy": "Privasi & persyaratan",
    "languageSaved": "Bahasa disimpan"
  }
}
</i18n>

<script>
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import CentralizeLayout from "@/layouts/CentralizeLayout";
import { mapGetters, mapActions } from "vuex";

const errorCodes = ["ExpiredCodeException"];
const fallbackErrorCode = "unspecific";

export default {
  components: {
    CentralizeLayout
  },
  props: {
    email: {
      type: String
    },
    lang: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      isLoading: false,
      submitted: false,
      form: {
        email: "",
        code: ""
      },
      timerEnabled: false,
      timerCount: 30
    };
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationStatus",
      "authenticationStatus",
      "temp",
      "username"
    ]),
    errorCode() {
      return errorCodes.includes(this.authenticationStatus.code)
        ? this.authenticationStatus.code
        : fallbackErrorCode;
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      code: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      }
    }
  },
  mounted() {
    this.clearAuthenticationStatus();
    this.$v.form.email.$model = this.$route.params.email;
    if (this.lang === "en") {
      this.$root.$i18n.locale = "en";
    } else {
      this.$root.$i18n.locale = "id";
    }
  },
  methods: {
    ...mapActions("auth", [
      "clearAuthenticationStatus",
      "confirmSignUp",
      "resendVerificationCode",
      "signIn",
      "setTemp"
    ]),
    ...mapActions("profile", ["setLanguage", "createProfile"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error && this.submitted ? false : null;
    },
    setLanguageEn() {
      this.$router.replace({ query: { lang: "en" } });
    },
    setLanguageId() {
      this.$router.replace({ query: { lang: "id" } });
    },
    async onSubmit() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      this.clearAuthenticationStatus();
      await this.confirmSignUp({
        username: this.form.email,
        code: this.form.code
      });
      await this.signIn({
        username: this.form.email,
        password: this.temp,
        remember: false
      });
      await this.createProfile({
        username: this.username,
        email: this.form.email
      });
      this.setTemp(null);
      if (!this.hasAuthenticationStatus) {
        this.$router.push({ name: "Login", query: { email: this.email } });
      }
      this.isLoading = false;
    },
    async resendCode() {
      if (this.$v.form.email.$invalid) {
        return;
      }
      try {
        this.clearAuthenticationStatus();
        await this.resendVerificationCode({ email: this.form.email, vm: this });
        this.timerEnabled = true;
      } catch (error) {
        this.timerEnabled = false;
      }
    }
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value === 0) {
          this.timerEnabled = false;
          this.timerCount = 30;
        }
      },
      immediate: true
    },

    $route() {
      if (this.$route.query.lang === "id") {
        this.$root.$i18n.locale = "id";
      } else {
        this.$root.$i18n.locale = "en";
      }
    }
  }
};
</script>
